<template>
  <v-form ref="form">
    <v-card class="ma-4" variant="outlined">
      <v-card-title class="HeaderTitle">
        <v-toolbar color="primary" density="compact" flat>
          <v-toolbar-title class="textcolor"> Bankverbindung </v-toolbar-title>
        </v-toolbar>
      </v-card-title>

      <v-card-text>
        <v-col v-if="loading == false">
          <v-row class="ma-1" v-if="Vorhanden('Bank') == true">
            <v-combobox
              v-model="$store.state.Bank.Name"
              :items="Banken"
              :label="$store.state.NameDisplayName"
              color="primary"
              variant="outlined"
              density="compact"
              @update:modelValue="setDaten()"
              :rules="[rules.required('BankName', $store.state.Bank.Name)]"
            ></v-combobox>
          </v-row>

          <v-row class="ma-1" v-if="Vorhanden('Kontoinhaber') == true">
            <v-text-field
              v-model="$store.state.Bank.Kontoinhaber"
              :label="$store.state.KontoinhaberDisplayName"
              color="primary"
              density="compact"
              variant="outlined"
              :rules="[
                rules.required('Kontoinhaber', $store.state.Bank.Kontoinhaber),
              ]"
            >
            </v-text-field>
          </v-row>

          <v-row class="ma-1" v-if="Vorhanden('IBAN') == true">
            <v-text-field
              v-model="$store.state.Bank.IBAN"
              :label="$store.state.IBANDisplayName"
              color="primary"
              variant="outlined"
              density="compact"
              :rules="[
                rules.required('IBAN', $store.state.Bank.IBAN),
                rules.IBANCheck,
              ]"
            >
            </v-text-field>
          </v-row>

          <v-row class="ma-1">
            <v-text-field
              v-if="Vorhanden('BIC') == true"
              class="mr-1"
              v-model="$store.state.Bank.BIC"
              :label="$store.state.BICDisplayName"
              color="primary"
              variant="outlined"
              density="compact"
              :rules="[rules.required('BIC', $store.state.Bank.BIC)]"
            >
            </v-text-field>

            <v-text-field
              v-if="Vorhanden('Bankinstitut') == true"
              v-model="$store.state.Bank.Bankinstitut"
              :label="$store.state.BankinstitutDisplayName"
              color="primary"
              variant="outlined"
              density="compact"
              :rules="[
                rules.required('Bankinstitut', $store.state.Bank.Bankinstitut),
              ]"
            >
            </v-text-field>
          </v-row>
        </v-col>

        <v-row class="ma-2" v-if="loading == true">
          <loadingscreen />
        </v-row>
        <v-row
          v-if="Vorhanden('SonstigeButtons') == true"
          class="mr-2 ml-3 mb-1"
        >
          <sub> <h1>*</h1></sub>
          <h4>Pflichtfelder</h4></v-row
        >
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script setup>
import Errors from "@/components/main/Errors.vue";
import loadingscreen from "@/components/main/loadingscreen.vue";
import dbhelper from "@/plugins/dbHelper.js";
import { ref, onMounted } from "vue";
import { useStore } from "vuex";

defineExpose({
  checkvalidate,
  resetFelder,
});

const Bankenurl = "GETBanken";

const store = useStore();

var form = ref(null);
var loading = ref(false);
var BankenItems = ref([]);
var Banken = ref([]);

var rules = ref({
  required(Feld, value) {
    var istPflichtfeld = false;
    store.state.VertragsPflichtfelder.forEach((element) => {
      if (element == Feld) istPflichtfeld = true;
    });
    if (istPflichtfeld == true) return !!value || "Pflichtfeld";
    else return true;
  },
  IBANCheck(value) {
    var test = DoIBANCheck(value);
    if (test == true) return true;
    else return "Keine gültige IBAN";
  },
});

onMounted(async () => {
  loading.value = true;
  var response = await dbhelper.dbload(Bankenurl);
  console.log(response);
  var BankenDB = JSON.parse(response.DataArray);
  BankenDB.forEach((element) => {
    Banken.value.push(element.Name);
  });
  BankenItems.value = BankenDB;
  loading.value = false;
});

function setDaten() {
  BankenItems.value.forEach((element) => {
    if (element.Name == store.state.Bank.Name) {
      store.state.Bank.Bankinstitut = element.Name;
      store.state.Bank.BIC = element.BIC;
    }
  });
}

// Prüft ob es das Feld angezeigt werden soll.
// Welche Felder anezeigt werden sollen werden in der INI bestimmmt.
function Vorhanden(Feld) {
  var FeldNichtvorhandewn = false;
  store.state.FelderNichtAnzeigen.forEach((element) => {
    if (element == Feld) FeldNichtvorhandewn = true;
  });
  if (FeldNichtvorhandewn == true) return false;
  else return true;
}

async function checkvalidate() {
  let test = await form.value.validate();
  if (test.valid == true) return true;

  return false;
}

function resetFelder() {
  form.value.reset();
}

function mod97(digital) {
  digital = digital.toString();
  let checksum = digital.slice(0, 2);
  let fragment = "";
  for (let offset = 2; offset < digital.length; offset += 7) {
    fragment = checksum + digital.substring(offset, offset + 7);
    checksum = parseInt(fragment, 10) % 97;
  }
  return checksum;
}

function DoIBANCheck(IbanString) {
  const codeLengths = {
    AD: 24,
    AE: 23,
    AL: 28,
    AT: 20,
    AZ: 28,
    BA: 20,
    BE: 16,
    BG: 22,
    BH: 22,
    BI: 28,
    BR: 29,
    BY: 28,
    CH: 21,
    CR: 22,
    CY: 28,
    CZ: 24,
    DE: 22,
    DK: 18,
    DO: 28,
    EE: 20,
    EG: 29,
    ES: 24,
    LC: 32,
    FI: 18,
    FO: 18,
    FR: 27,
    GB: 22,
    GE: 22,
    GI: 23,
    GL: 18,
    GR: 27,
    GT: 28,
    HR: 21,
    HU: 28,
    IE: 22,
    IL: 23,
    IQ: 23,
    IS: 26,
    IT: 27,
    JO: 30,
    KW: 30,
    KZ: 20,
    LB: 28,
    LI: 21,
    LT: 20,
    LU: 20,
    LV: 21,
    LY: 25,
    MC: 27,
    MD: 24,
    ME: 22,
    MK: 19,
    MR: 27,
    MT: 31,
    MU: 30,
    NL: 18,
    NO: 15,
    PK: 24,
    PL: 28,
    PS: 29,
    PT: 25,
    QA: 29,
    RO: 24,
    RS: 22,
    SA: 24,
    SC: 31,
    SD: 18,
    SE: 24,
    SI: 19,
    SK: 24,
    SM: 27,
    ST: 25,
    SV: 28,
    TL: 23,
    TN: 24,
    TR: 26,
    UA: 29,
    VA: 22,
    VG: 24,
    XK: 20,
  };

  const iban = IbanString;

  const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);
  if (!code || iban.length !== codeLengths[code[1]]) {
    return false;
  }
  const digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, (letter) => {
    return (letter.charCodeAt(0) - 55).toString();
  });

  if (mod97(digits) === 1) {
    return true;
  } else return false;
}
</script>

<style>
.textcolor {
  color: white;
}
</style>